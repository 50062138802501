import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import loadable from "@loadable/component";

// Components
import { GatsbySeo, FAQJsonLd, ArticleJsonLd } from "gatsby-plugin-next-seo";
import {
  generateFaqItems,
  getFirstImageFromAllContentComponents,
  getFirstParagraphFromAllContentComponents,
} from "../utils/schemas";

// Loadable Components
const FaqComponent = loadable(() => import("../components/FaqComponent"));
const CasinoList = loadable(() => import("../components/CasinoList"));
const ContentComponent = loadable(() =>
  import("../components/ContentComponent")
);

const WebsitePage = ({ data, location }) => {
  const pageData = data?.contentfulWebsitePage;

  const components = pageData?.components;

  const pathName = location.pathname;

  const firstParagraph = getFirstParagraphFromAllContentComponents(
    components,
    data?.contentfulWebsitePage?.pageName === "Main Page" ? "home" : null
  );
  const firstImage = getFirstImageFromAllContentComponents(components);

  const slugId = pageData.slug === "/" ? "" : `${pageData.slug}/`;
  return (
    <Layout location={location}>
      <div className="content container" style={{ minHeight: "700px" }}>
        {pageData?.pageName && (
          <div className="entry-header has-text-align-center header-footer-group">
            <div className="entry-header-inner section-inner medium">
              <h1 className="has-text-align-center entry-title">
                {pageData?.pageName}
              </h1>
            </div>
          </div>
        )}
        <ArticleJsonLd
          url={`https://casinosinontario.com/${slugId}`}
          headline={data?.contentfulWebsitePage?.pageName}
          images={[firstImage]}
          datePublished={pageData.createdAt}
          dateModified={pageData.updatedAt}
          proficiencyLevel="Expert"
          authorName="editor"
          publisherName="CasinosInOntario"
          publisherLogo="https://images.ctfassets.net/fvwydvqhmcqs/6sra1XY9KAJwgVj53WvnSc/50b48aff690f240f7585d58e76d4d627/casinos-in-ontario.webp?w=240&h=134&q=50&fm=webp&bg=transparent"
          description={firstParagraph}
          overrides={{
            "@type": "TechArticle", // set's this as a blog post.
          }}
        />
        {components?.map((component) => {
          switch (component.__typename) {
            case "ContentfulSeoComponent":
              let canonicalUrl;
              pathName === "/"
                ? (canonicalUrl = `https://casinosinontario.com/`)
                : (canonicalUrl = `https://casinosinontario.com/${component?.seoSlug}/`);

              return (
                <GatsbySeo
                  title={component?.seoTitle}
                  description={component?.seoDescription?.seoDescription}
                  canonical={canonicalUrl}
                />
              );
            case "ContentfulCasinoList":
              return (
                <CasinoList
                  title={component?.title}
                  casinosCadsData={component?.casinoList}
                />
              );
            case "ContentfulFaqComponent":
              return (
                <>
                  <FAQJsonLd
                    questions={generateFaqItems(component?.faqItems)}
                  />
                  <FaqComponent
                    faqItems={component?.faqItems}
                    title={component?.title}
                  />
                </>
              );
            case "ContentfulContentComponent":
              return <ContentComponent data={component?.contentBody} />;
            default:
              return <></>;
          }
        })}
      </div>
    </Layout>
  );
};

export default WebsitePage;

export const query = graphql`
  query page($id: String!) {
    contentfulWebsitePage(id: { eq: $id }) {
      id
      pageName
      createdAt(formatString: "YYYY-MM-DD")
      updatedAt(formatString: "YYYY-MM-DD")
      slug
      components {
        ... on ContentfulSeoComponent {
          __typename
          seoSlug
          seoTitle
          seoDescription {
            seoDescription
          }
        }
        ... on ContentfulContentComponent {
          __typename
          contentBody {
            childrenMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulFaqComponent {
          __typename
          title
          faqItems {
            faqQuestion
            faqAnswer {
              faqAnswer
            }
          }
        }
        ... on ContentfulCasinoList {
          __typename
          title
          casinoList {
            games
            description
            features
            winRate
            payoutSpeed
            link
            logo {
              gatsbyImage(width: 100, quality: 90, formats: [AUTO, WEBP], placeholder: NONE)
            }
            minimumDeposit
            name
            rating
          }
        }
      }
    }
  }
`;
