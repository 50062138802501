export function generateFaqItems(faqItems) {
  const newArrayOfObj = faqItems.map(
    ({ faqQuestion: question, faqAnswer: answer, ...rest }) => ({
      question,
      answer: answer.faqAnswer,
      ...rest,
    })
  );

  return newArrayOfObj;
}

export function getFirstParagraphFromAllContentComponents(
  components,
  typePage
) {
  let firstParagraph;

  const allPageContentComponents = [];
  components.map((component) => {
    if (component.__typename === "ContentfulContentComponent") {
      allPageContentComponents.push(
        component.contentBody.childrenMarkdownRemark[0].html
      );
    }

    if (allPageContentComponents.length) {
      firstParagraph = allPageContentComponents[0];
    }
  });

  const matches = [];

  if (typePage === "home") {
    firstParagraph.replace(/<p>(.*?)<\/p>/g, function () {
      // arguments[0] is the entire match
      matches.push(arguments[1]);
    });
  } else {
    firstParagraph.replace(/<p><strong>(.*?)<\/strong><\/p>/g, function () {
      // arguments[0] is the entire match
      matches.push(arguments[1]);
    });
  }

  return matches[0];
}

export function getFirstImageFromAllContentComponents(components) {
  const allPageContentComponents = [];
  const imageMatches = [];
  components.map((component) => {
    if (component.__typename === "ContentfulContentComponent") {
      allPageContentComponents.push(
        component.contentBody.childrenMarkdownRemark[0].html
      );
    }
  });
  allPageContentComponents.map((content) => {
    if (content.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/g)) {
      const imgTag = content.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/g);
      imageMatches.push(imgTag);
    }
  });

  let sourceImage;
  const firstComponentWithImages = imageMatches[0];

  if (firstComponentWithImages.length && firstComponentWithImages.length > 0) {
    const source = firstComponentWithImages[0]
      .match(/src\s*=\s*"([^"]+)"/g)
      .map((x) => x.replace(/.*src="([^"]*)".*/, "$1"));
    sourceImage = source[0];
  }

  return (
    sourceImage ||
    "https://images.ctfassets.net/fvwydvqhmcqs/6sra1XY9KAJwgVj53WvnSc/50b48aff690f240f7585d58e76d4d627/casinos-in-ontario.webp?w=240&h=134&q=50&fm=webp&bg=transparent"
  );
}
